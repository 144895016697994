import React from "react";
import man from "../assets/images/glass-man.png";

const Empowering = () => {
  return (
    <div className=" mt-[100px] z-[2] lg:flex md:flex md:flex-row-reverse md:h-3/4">
      <div className="w-full m-auto">
        <div className=" m-auto md:ml-[-120px]">
          <img
            src={man}
            className="m-auto md:mt-[-70px] md:w-full md:ml-[-30px]"
          ></img>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="sm:w-3/5 md:w-3/4 m-auto md:h-1/16  md:mt-[0px]">
          <p
            style={{
              fontFamily: "'Montserrat', sans-serif", // Adjusted font-family value
            }}
            className="text-center text-[20px] m-5 md:text-left md:mt-[200px] md:w-[35rem]"
          >
            We are passionate about equipping the young one with the right tools
            they need for the 21st century.
          </p>
          <div className="flex m-5 ">
            <div className="m-auto inline-block md:ml-[0px]">
              <button
                style={{
                  fontFamily: "'Clash Display', sans-serif", // Adjusted font-family value
                }}
                className="btn m-auto btn-md  md:py-[14px] md:px-[22px]  "
              >
                <a href="/register">Register</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empowering;
