import Empowering from "../components/empowering";
import InstructorHeader from "../components/instructorHeader";
import Navbar from "../components/navbar";
import NewsHeader from "../components/newsHeader";
import background from '../assets/images/real_background_1 16.png'
import MissionHeader from "../components/missionHead";
import { GrAnnounce } from "react-icons/gr";
import Announcement from "../components/announcement";
import Contact from "../components/contact";
import Footer from "../components/footer";
import OurContact from "../components/ourContact";
import WomenEngineering from "../components/women-in-engineering";

const News = () => {
    return (
      <>
        <div
          style={{
            backgroundImage: `linear-gradient(45deg, white, rgba(245, 245, 251, 1), rgba(224, 225, 244), rgba(230, 230, 247, 1), rgba(255, 217, 249), rgba(254, 236, 252), white) `,
          }}
        >
          <NewsHeader />
          <WomenEngineering/>
        </div>
      </>
    );
};

export default News