import React, { useEffect, useState } from "react";

const RegistrationList = () => {
  const [registrations, setRegistrations] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://xplore-backend-o4me.onrender.com/api/registration"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setRegistrations(data);
        setCount(data.length);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mt-[200px] p-4">
      <h1 className="text-3xl font-bold mb-8 text-center">Registration List</h1>
      <h1 className="text-3xl font-bold mb-8 text-center">
        Number of Registered Participants:{" "}
        <span className="text-[red]">{count}</span>
      </h1>
      <ul className="space-y-8">
        {registrations
          .map((registration) => (
            <li
              key={registration.email}
              className="border rounded-lg p-6 shadow-md bg-white"
            >
              <p className="text-lg">
                <strong className="font-semibold">Name:</strong>{" "}
                {registration.firstName} {registration.lastName}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">Email:</strong>{" "}
                {registration.email}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">Phone:</strong>{" "}
                {registration.phone}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">
                  Preferred Learning Track:
                </strong>{" "}
                {registration.preferredLearningTrack}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">Gender:</strong>{" "}
                {registration.gender}
              </p>
              {/* <p className="text-lg">
                <strong className="font-semibold">Date of Birth:</strong>{" "}
                {new Date(registration.dateOfBirth).toLocaleDateString()}
              </p> */}
              <p className="text-lg">
                <strong className="font-semibold">Experience Level:</strong>{" "}
                {registration.experienceLevel}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">
                  Motivation for Joining:
                </strong>{" "}
                {registration.motivationForJoining}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">WhatsApp Number:</strong>{" "}
                {registration.whatsappNumber}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">Availability:</strong>{" "}
                {registration.availability}
              </p>
              <p className="text-lg">
                <strong className="font-semibold">
                  Additional Information:
                </strong>{" "}
                {registration.additionalInformation || "N/A"}
              </p>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default RegistrationList;
