import React from "react";

function RegistrationClosed() {
  return (
    <div className="relative w-full h-screen flex justify-center items-center bg-gray-200 overflow-hidden">
      <div className="absolute text-9xl text-[#000000] opacity-20">
        0<span className="animate-pulse text-[inherit]">:</span>
        00
      </div>
      <div className="absolute text-5xl text-[red] font-bold transform rotate-45">
        Registration Closed
      </div>
    </div>
  );
}

export default RegistrationClosed;
