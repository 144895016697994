import { useEffect } from "react";
import Announcement from "../components/announcement";
import ClassRoom from "../components/classroom-exp";
import Empowering from "../components/empowering";
import Footer from "../components/footer";
import LearningProgramming from "../components/learningProgramming";
import Navbar from "../components/navbar";
import OurContact from "../components/ourContact";
import OurPatners from "../components/ourPartners";
import "../css/index.css";
import Wrapper from "./wrapper";

const Home = () => {
  useEffect(() => {
    localStorage.removeItem("formData");
    localStorage.removeItem("courseFormData");
  }, []); // Adding an empty dependency array to run this effect only once on mount

  return (
    <>
      <div>
        <Wrapper>
          <Empowering />
        </Wrapper>
        <Wrapper>
          <ClassRoom />
        </Wrapper>
        <Wrapper>
          <LearningProgramming />
        </Wrapper>
        <Announcement />
        <OurPatners />
        <OurContact />
      </div>
    </>
  );
};

export default Home;
