import Logo from '../assets/images/logo1.png'


const Footer = () => {
     return(
        <>
             <footer
                   style={{
                    fontFamily : "'Montserrat', sans-serif", // Adjusted font-family value
                  }}
                 className='text-[#9D9898]'>
            <div className='sm:flex justify-start px-[20px] lg:px-[90px] pt-[40px] bg-[#090516]'>
                <div className='w-[60%] md:w-[40%] mb-[37px]'>
                    <div className="logo mb-[24px]">
                        <img className='w-[100px] sm:w-[150px]' src={Logo} alt="" />
                    </div>
                    <div className='hidden sm:block w-[80%]'>
                        <p>We want to create an atmosphere where students can connect what they have learnt in class to real-world problems specifically</p>
                    </div>
                </div>
                <div className='mt-5 mb-[37px]'>
                         <h5
                               style={{
                                fontFamily: "'Clash Display', sans-serif", // Adjusted font-family value
                                fontWeight:900
                    }}
                             className='font-bold text-[20px] mb-[9px]'>Courses</h5>
                    <ul>
                        <li className='mb-[9px]'>Web Development</li>
                        <li className='mb-[9px]'>Scratch</li>
                        <li className='mb-[9px]'>Python</li>
                        <li className='mb-[9px]'>Mblock</li>
                        <li className='mb-[9px]'>C++</li>
                    </ul>
                </div>
                <div className='block sm:hidden pb-[37px]'>
                         <h5
                               style={{
                                fontFamily: "'Clash Display', sans-serif", // Adjusted font-family value
                                fontWeight:900
                    }}
                             className='font-bold text-[20px] mb-[9px]'>Support</h5>
                    <p>Help center</p>
                </div>
            </div>
            <div className='bg-[#090516] sm:bg-[#030209] text-white py-[21px] text:left text-[14px] sm:text-right px-[20px] lg:px-[90px]'>
                <p>© 2020 STEMi | All Rights Reserved | Designed By <span className='text-[#10A030]'>Xplorestemi</span></p>
            </div>
        </footer>
        </>
     )
};

export default Footer;