import Announce from '../assets/images/announce-img.png'

const Announcement = () => {
     

    return(
        <div className="flex justify-center md:justify-evenly gap-7 md:border border-black md:rounded-[12px] px-[38px] md:px-[10px] lg:px-[38px] lg:[38px] py-[45px] md:py-[20px] lg:py-[45px] my-[20px] md:mx-[20px] lg:mx-[90px] bg-[#D1C4FD] md:bg-white md:bg-opacity-0 text-primary md:text-dark mt-[50px]   mb-[50px]">
            <div className='hidden md:block'>
                <img src={Announce} alt="" />
            </div>
            <div className='text-center md:text-left'>
                <h3 className="font-bold text-[27px] sm:text-[40px] md:text-[24px] mb-[8px] lg:mb-[24px] md:text-[#4B4B4B]"
                     style={{
                        fontFamily : "'Clash Display', sans-serif", // Adjusted font-family value
                      }}
                >Announcement</h3>
                <p className='text-[16px] sm:text-[20px] md:text-[14px] mb-[24px] w-4/5 m-auto text-[#4B4B4B] md:ml-[unset] '
                 style={{
                    fontFamily : "'Montserrat', sans-serif", // Adjusted font-family value
                  }}
                >
                    It's Finally Here... <br />
                    Online 1 Year Robotics  bootcamp. <br />
                    Proudly Sponsored By XploreSTEMi With Partners Across Africa.
                </p>
                <p className='text-[16px] mb-[41px] font-semibold text-[black] md:text-black'
                 style={{
                    fontFamily : "'Montserrat', sans-serif", // Adjusted font-family value
                  }}
                >Click the button to Join the community</p>
                <div className='flex justify-center md:justify-end'>
                    <a href='/register' className='btn-md md:btn-sm lg:btn-md font-medium'
                     style={{
                        fontFamily : "'Clash Display', sans-serif", // Adjusted font-family value
                      }}
                    >Join Now!!!</a>
                </div>
            </div>
        </div>    
    )
};

export default Announcement;