import React from 'react'
import icon2 from "../assets/images/patners.svg";

function OurPartners() {
  return (
    <div className=" m-auto mt-[50px] mb-[50px]   ">
      <h1
        style={{
          fontFamily: "'Clash Display', sans-serif", 
          fontWeight: 900,
        }}
        className="text-center m-5 text-[2rem] md:text-[2rem] text-dark"
      >
        Our Partners
      </h1>
      <div className="flex">
        <img src={icon2} className="w-[85%] m-auto" />
      </div>
    </div>
  );
}

export default OurPartners;
