import React from 'react'

function NewsHeader() {
  return (
    <div className="m-5 pt-36">
      <h1
        style={{
          fontFamily: "'Clash Display', sans-serif", // Adjusted font-family value
        }}
        className="text-center text-[50px] md:text-[50px] m-7 font-bold text-dark"
      >
        News
      </h1>
      <div className="  m-auto md:w-2/3 pb-[50px] ">
        <p
          style={{
            fontFamily: "'Montserrat', sans-serif", // Adjusted font-family value
          }}
          className="text-center text-dark m-[10%]"
        >
          Tech enthusiasts, get ready! The Xplore-STEMI Bootcamp is launching
          soon, offering an exciting opportunity to dive into the world of
          technology. This intensive program will focus on essential skills such
          as HTML, CSS, UI/UX design, and more. Created by industry experts, the
          bootcamp promises a hands-on curriculum that includes real-world
          projects, ensuring participants gain practical, job-ready experience.
        </p>
      </div>
    </div>
  );
}

export default NewsHeader
