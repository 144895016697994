import React from "react";
import tv from "../assets/images/tv.svg";
import icon2 from "../assets/images/Instagram.svg";
import student from "../assets/images/real_student 1.svg";

import tutorial from "../assets/images/teaching_image_real 1.svg";

function LearningProgramming() {
  return (
    <div
      style={{
        fontFamily: "'Clash Display', sans-serif",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
      className="w-full md:w-[90%] m-auto"
    >
      <div className="w-[80%]  md:flex md:m-auto mt-[50px] md:mt-[70px] mb-[50px]">
        <div className="m-auto md:w-[50%] ">
          <h1
            style={{
              fontWeight: 900,
            }}
            className="text-center m-5 text-[1.4rem] md:text-[2rem] text-dark md:text-start"
          >
            Learning Programming
          </h1>
          <p className="w-[90%] m-auto mb-50 text-center break-words md:text-start md:ml-[20px]  ">
            Computer Programming is one of the greatest tools that has changed
            the world. In our 21st century, you cannot talk about technology
            without mentioning programming. Most of our devices and gadgets
            today are programmed to achieve their functionality. Our training
            covers programming so students will be empowered to stand out in
            today's world.
          </p>
        </div>
        <div className="w-full md:w-[50%] mb-[50px] m-auto">
          <img src={tv} className=" mt-[30px] m-auto " />
          <p className="b text-center mt-[15px] ">Watch Video to understand programming</p>
        </div>
      </div>
      <div className="w-[80%]  p-[10px] md:flex md:m-auto  md:mt-[70px]">
        <div className="w-full md:w-[50%] mb-[50px] m-auto">
          <img src={tutorial} className=" mt-[30px] m-auto " />
        </div>
        <div className="m-auto md:w-[50%] ">
          <h1
            style={{
              fontWeight: 900,
            }}
            className="text-center m-5 text-[1.4rem] md:text-[2.1rem] md:text-start text-dark"
          >
            Learn through tutorials and from our mentors
          </h1>
          <p className="w-[90%]  m-auto md:ml-[20px] mb-50 text-center break-words md:text-start ">
            We have enough resources and mentors you will learn from. What are
            you waiting for? Enroll now to get started.
          </p>
        </div>
      </div>
      <div className="w-[80%]  md:flex md:m-auto mt-[50px] md:mt-[70px] mb-[50px]">
        <div className="w-full md:w-[50%] mb-[50px] m-auto">
          <img src={student} className=" mt-[30px] m-auto " />
        </div>
        <div className="m-auto md:w-[50%] ">
          <h1
            style={{
              fontWeight: 900,
            }}
            className="text-center m-5 text-[1.4rem] md:text-[2rem] text-dark md:text-start"
          >
            Work On Real Life Projects
          </h1>
          <p className="w-[90%] m-auto mb-50 text-center break-words md:text-start md:ml-[20px]  ">
            Our students and participants will work on real life projects to
            enable them develop competence in handling tech projects.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LearningProgramming;
