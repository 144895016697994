import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";

const Navbar = () => {
  const location = useLocation();
  const [height, setHeight] = useState(0);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      //   console.log('Page scrolled:', window.scrollY);
      setHeight(window.scrollY);
      //   console.log(height)
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [height]);

  return (
    <>
      <div
        className={
          height < 70
            ? "flex justify-between items-center px-[30px] md:px-[139px] py-[5px] pt-[20px] md:py-[15px] md:pt-[35px] -mb-32 bg-none z-[999] relative top-0"
            : "flex justify-between items-center px-[30px] md:px-[139px] py-[15px] md:py-[20px] bg-[#F9F9F9] z-[1000] sticky top-0"
        }
      >
        <div className="logo">
          <a href="/">
            <img className="w-[100px] sm:w-[120px]" src={Logo} alt="Logo" />
          </a>
        </div>
        <div
          style={{
            fontFamily: "'Clash Display', sans-serif",
            fontWeight: 400,
          }}
          className="nav-content hidden md:block md:w-[50%] lg:w-[40%]"
        >
          <ul className="flex justify-between items-center">
            <a href="/">
              <li className="text-primary font-medium">Home</li>
            </a>
            <a href="/training">
              <li className="text-primary font-medium">Training</li>
            </a>
            <a href="/instructors">
              <li className="text-primary font-medium">Instructors</li>
            </a>
            <a href="/about">
              <li className="text-primary font-medium">About</li>
            </a>
            <a href="/news">
              <li className="text-primary font-medium">News</li>
            </a>
          </ul>
        </div>
        <div className="nav-contact hidden md:block">
          <button
            style={{
              fontFamily: "'Clash Display', sans-serif",
              fontWeight: 400,
            }}
            className="btn-sm"
          >
            <a href="/register">Register</a>
          </button>
        </div>
        <div
          className={
            show
              ? "hidden"
              : "nav-burger md:hidden cursor-pointer transition-all duration-500"
          }
          onClick={handleShow}
        >
          <FiMenu size={35} />
        </div>
        <div
          className={
            show
              ? "sm:block cursor-pointer md:hidden"
              : "hidden transition-all duration-500"
          }
          onClick={handleShow}
        >
          <GrClose size={30} />
        </div>
      </div>
      <div
        style={{
          fontFamily: "'Clash Display', sans-serif",
        }}
        className={
          show
            ? "side-nav fixed left-0 top-0 w-full h-screen sm:w-[250px] bg-white flex justify-between px-[20px] pt-[20px] md:hidden z-[1000]"
            : "side-nav absolute left-[-250px] top-0 w-[50px] bg-white flex justify-between px-[20px] pt-[20px]"
        }
      >
        <div>
          <div className="logo mb-[35px]">
            <a href="/">
              <img className="w-[100px] sm:w-[150px]" src={Logo} alt="Logo" />
            </a>
          </div>
          <div className="nav-contact mt-5 mb-[44px]">
            <button className="btn-sm">
              <a href="/register">Register</a>
            </button>
          </div>
          <ul>
            <a href="/">
              <li className="text-primary font-medium text-[20px] mb-[24px]">
                Home
              </li>
            </a>
            <a href="/training">
              <li className="text-primary font-medium text-[20px] mb-[24px]">
                Training
              </li>
            </a>
            <a href="/instructors">
              <li className="text-primary font-medium text-[20px] mb-[24px]">
                Instructors
              </li>
            </a>
            <a href="/about">
              <li className="text-primary font-medium text-[20px] mb-[24px]">
                About
              </li>
            </a>
            <a href="/news">
              <li className="text-primary font-medium text-[20px]">News</li>
            </a>
          </ul>
          <div className="absolute bottom-1 w-full">
            <p className="text-[12px] text-center">
              copyright &copy; xplorestemi.org
            </p>
          </div>
        </div>
        <div className="sm:hidden" onClick={handleShow}>
          <GrClose size={30} />
        </div>
      </div>
    </>
  );
};

export default Navbar;
