import React from 'react'
import woemn from '../assets/images/women.svg'


function WomenEngineering() {
  return (
    <div
      style={{
        fontFamily: "'Montserrat', sans-serif",
      }}
      className=" mt-[100px] mb-[100px]  "
    >
      <div className="md:flex md:w-[80%] md:m-auto ">
        <div className=" text-[1rem]   w-[70%] md:w-[45%] flex bg-white j m-auto rounded-lg p-[20px] shadow-2xl mb-[50px] ">
          <img src={woemn} />
          <div className=" w-fit p-[15px]  ">
            <p className="text-[#4B4B4B]">
              We are pleased to announce our partnership with women in
              Engineering
            </p>
            <p className="text-[0.8rem] ">16th September 2021</p>
          </div>
        </div>
        <div className=" w-[70%] text-[1rem]  md:w-[45%] flex bg-white j m-auto rounded-lg p-[20px] shadow-2xl mb-[50px] ">
          <img src={woemn} />
          <div className=" w-fit p-[15px]  ">
            <p className="text-[#4B4B4B]">
              We are pleased to announce our partnership with women in
              Engineering
            </p>
            <p className="text-[0.8rem] ">16th September 2021</p>
          </div>
        </div>
      </div>
      <div className="md:flex md:w-[80%] md:m-auto ">
        <div className=" text-[1rem]   w-[70%] md:w-[45%] flex bg-white j m-auto rounded-lg p-[20px] shadow-2xl mb-[50px] ">
          <img src={woemn} />
          <div className=" w-fit p-[15px]  ">
            <p className="text-[#4B4B4B]">
              We are pleased to announce our partnership with women in
              Engineering
            </p>
            <p className="text-[0.8rem] ">16th September 2021</p>
          </div>
        </div>
        <div className=" w-[70%] text-[1rem]  md:w-[45%] flex bg-white j m-auto rounded-lg p-[20px] shadow-2xl mb-[50px] ">
          <img src={woemn} />
          <div className=" w-fit p-[15px]  ">
            <p className="text-[#4B4B4B]">
              We are pleased to announce our partnership with women in
              Engineering
            </p>
            <p className="text-[0.8rem] ">16th September 2021</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WomenEngineering
