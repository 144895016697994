import React from 'react'

function TrainingHeader() {
  return (
    <div>
         <h1 
      style={{
        fontFamily : "'Clash Display', sans-serif", // Adjusted font-family value
      }}
      className='text-center text-[50px] md:text-[50px] pt-36 p-[35px] font-bold text-dark'>
        Training
      </h1>
    </div>
  )
}

export default TrainingHeader