import Announcement from "../components/announcement";
import Navbar from "../components/navbar";
import TrainingHeader from "../components/trainingHeader";
import cac from "../assets/images/in Calvary Arrows.svg";
import twentyTwenty from "../assets/images/bootcamp2020.svg";
import twentyTw0 from "../assets/images/bootcamp2022.svg";

const Trainings = () => {
  return (
    <>
      <div
        style={{
          fontFamily: "'Clash Display', sans-serif",
        }}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(45deg, white, rgba(245, 245, 251, 1), rgba(224, 225, 244), rgba(230, 230, 247, 1), rgba(255, 217, 249), rgba(254, 236, 252), white)
             `,
          }}
        >
          <TrainingHeader />
          <Announcement />
        </div>
        <h1
          style={{
            fontWeight: 550,
          }}
          className="text-center m-5 text-[2rem] md:text-[2rem] text-dark"
        >
          A Record Of Our Training
        </h1>
        <div className="w-[80%] md:w-[80%] m-auto">
          <p
            style={{
              fontFamily: "'Montserrat', sans-serif",
            }}
            className="text-center break-words"
          >
            ExploreStemi from the beginning up till now have carried out several
            bootcamps and other training programs with the aim of equipping
            youths with necessary tech skills .
          </p>
        </div>
        <h1
          style={{
            fontWeight: 550,
          }}
          className="text-center m-5 text-[2rem] md:text-[2rem] text-dark"
        >
          Gallery
        </h1>
        <div className=" md:flex " >
          <img className=" m-auto mt-[50px] mb-[50px] " src={cac} />
          <img className=" m-auto mt-[50px] mb-[50px] " src={twentyTwenty} />
          <img className=" m-auto mt-[50px] mb-[50px] " src={twentyTw0} />
        </div>
      </div>
    </>
  );
};

export default Trainings;
