import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Wrapper from "../wrapper";
import Line from '../../assets/images/Line.svg';
import RegistrationClosed from "./registrationClosed";

function CourseSelection() {
 const [formData, setFormData] = useState({
   preferredLearningTrack: "",
   experienceLevel: "",
   availability: "",
   requirements: "", // Updated to a string
   motivationForJoining: "",
   additionalInformation: "",
 });

       useEffect(() => {
         const savedFormData = JSON.parse(
           localStorage.getItem("courseFormData")
         );
         if (savedFormData) {
           setFormData(savedFormData);
         }
       }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      const newFormData = {
        ...formData,
        requirements: {
          ...formData.requirements,
          [name]: checked,
        },
      };
      setFormData(newFormData);
      localStorage.setItem("courseFormData", JSON.stringify(newFormData));
    } else {
      const newFormData = {
        ...formData,
        [name]: value,
      };
      setFormData(newFormData);
      localStorage.setItem("courseFormData", JSON.stringify(newFormData));
    }
  };

  const wordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };


  return (
    <div style={{ fontFamily: "'Clash Display', sans-serif" }}>
      <RegistrationClosed/>
      {/* <Wrapper>
        <h1
          style={{ fontFamily: "'Clash Display', sans-serif", fontWeight: 900 }}
          className="text-center mt-[150px] m-5 text-[30px] md:text-[35px] text-primary font-bolder"
        >
          Let’s get you registered
        </h1>
        <p
          style={{ fontFamily: "'Clash Display', sans-serif", fontWeight: 900 }}
          className="text-center m-5 text-[22px] md:text-[25px] text-[#4B4B4B] font-bolder"
        >
          Enter your details to get going
        </p>
        <nav>
          <ul
            style={{
              fontFamily: "'Clash Display', sans-serif",
              fontWeight: 500,
            }}
            className="flex w-[90%] m-auto justify-between text-[14px]"
          >
            <li>
              <NavLink
                to="/register"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                General Details
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/course-selection"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                Course Selection
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/submit"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                Submit
              </NavLink>
            </li>
          </ul>
        </nav>
        <img src={Line} className="w-[90%] m-auto h-[20px] " />
        <form className="bg-white rounded-md shadow-2xl w-[90%] md:w-[70%] m-auto p-[30px] mt-[50px] mb-[30px]">
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Learning Track<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <select
                name="preferredLearningTrack"
                value={formData.preferredLearningTrack}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              >
                <option value="">Select your learning track</option>
                <option value="Data Science">Data Science</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Frontend">Frontend</option>
                <option value="Backend">Backend</option>
                <option value="Scratch">Scratch</option>
                <option value="C++">C++</option>
              </select>
              <p className="text-[#7B7A7A] text-[14px]">
                NB: Each person can only register for one learning track
              </p>
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Experience Level<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <select
                name="experienceLevel"
                value={formData.experienceLevel}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              >
                <option value="">Select your experience level</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </select>
              <p className="text-[#7B7A7A] text-[14px]">
                Choose your experience level in the selected learning track
              </p>
            </div>
          </div>
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Availability<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <select
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              >
                <option value="">Select your availability</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <p className="text-[#7B7A7A] text-[14px]">
                This boot-camp will expect a dedication of at least 8hrs/week
                (3hrs live session for each track + 1hr for seminars + 4hrs) for
                self-study to do assignments and tasks assigned. Can you
                dedicate 8hrs a week for the entire duration of the boot-camp?
              </p>
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Requirements<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <div className="flex space-x-4 mt-[5px]">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requirements" // Use the same name for radio buttons to group them
                    value="computer" // Use value attributes to differentiate the options
                    checked={formData.requirements === "computer"} // Set checked based on selected value
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Computer
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requirements" // Use the same name for radio buttons to group them
                    value="any" // Use value attributes to differentiate the options
                    checked={formData.requirements === "any"} // Set checked based on selected value
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Any Other
                </label>
              </div>
              <p className="text-[#7B7A7A] text-[14px]">
                This is an online boot-camp and that will require a computer (PC
                or any other) to ensure smooth running. From the checklist
                below, check all you have.
              </p>
            </div>
          </div>
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Motivation for Joining
                <span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <textarea
                name="motivationForJoining"
                value={formData.motivationForJoining}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
                placeholder="Write your motivationForJoining for joining"
                maxLength="2500"
              />
              <p className="text-[#7B7A7A] text-[14px]">
                Please, briefly describe WHY you want to join this boot-camp and
                WHAT you hope to achieve from it.
              </p>
              <p className="text-[#7B7A7A] text-[14px]">
                {wordCount(formData.motivationForJoining)} / 500 words
              </p>
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Additional Comments or Questions
              </label>
              <br />
              <textarea
                name="additionalInformation"
                value={formData.additionalInformation}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
                placeholder="Write any additional comments or questions"
                maxLength="2500"
              />
              <p className="text-[#7B7A7A] text-[14px]">
                If you have any additional comments or questions, enter them
                above.
              </p>
              <p className="text-[#7B7A7A] text-[14px]">
                {wordCount(formData.additionalInformation)} / 500 words
              </p>
            </div>
          </div>
        </form>
        <div className="flex justify-between w-[90%] m-auto">
          <NavLink
            to="/register"
            className="p-[10px]  rounded-md m-[20px] bg-primary text-white hover:cursor-pointer"
          >
            Previous
          </NavLink>
          <NavLink
            to={{
              pathname: "/submit",
              state: { formData },
            }}
            className="p-[10px]  rounded-md m-[20px] bg-primary text-white hover:cursor-pointer"
          >
            Save & Continue
          </NavLink>
        </div>
      </Wrapper> */}
    </div>
  );
}

export default CourseSelection;
