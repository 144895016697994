import React from 'react'

function MissionHeader() {
  return (
    <div className='pt-36 m-5'>
      <h1
           style={{
            fontFamily : "'Clash Display', sans-serif", 
          }}
        className='text-center text-[50px] md:text-[50px] m-7 font-bold text-dark'>
        Mission
      </h1>
      <div className='  m-auto md:w-2/3  pb-[50px] '>
        <p
              style={{
                fontFamily : "'Montserrat', sans-serif", 
              }}
          className='text-center text-dark'>
      To ignite a passion for STEM in primary and secondary school students from disadvantage backgrounds through hands-on learning in coding, robotics, and science, empowering them with diverse skills to solve real-world problems and contribute to the growth of developing nations.
      </p>
      </div>
    </div>
  )
}

export default MissionHeader
