import React from 'react'

function OurContact() {
  return (
    <div className='flex md:w-3/4 sm:m-auto  mt-[50px] mb-[50px] '>
      <div
        className='  text-[#4B4B4B] w-1/2  hidden md:block'
        style={{
          fontFamily : "'Montserrat', sans-serif", 
        }}>
        <h2
           style={{
            fontFamily: "'Clash Display', sans-serif",
            fontWeight: 500,
            color: 'black',
             marginTop:'10px'
          }}
        > Our Programming Courses</h2>
        <p className='mt-[25px]'>Scratch</p>
        <p className='mt-[25px]' >Python</p>
        <p className='mt-[25px]' >Mblock</p>
        <p className='mt-[25px]'>Visual Basic</p>
      </div>
      <div
        className=' m-auto'
        style={{
          fontFamily : "'Montserrat', sans-serif", 
          color: 'white',
          fontSize: '15px',
          margin: 'auto'
        }}>
        <div className='bg-[#231651] w-[22rem] p-[30px] m-auto  rounded-tl-lg rounded-tr-lg'>
          <h2
               style={{
                fontFamily: "'Clash Display', sans-serif",
                fontWeight: 500,
              }}>
            Our Contact
          </h2>
          <p
            style={{
              fontFamily: "'Clash Display', sans-serif",
              fontWeight: 100,
            }}
          className='mt-[20px] '
          >
          Email :  info@explorestemi.com 
          </p>
        </div>
        <div className='bg-[#B7990D] w-[22rem]  p-[20px] rounded-bl-lg rounded-br-lg  mb-[30px]'>
          <p>
          We will get back to you within 24 hours, or call us any day, 09:00 AM - 6:00 PM
          </p>
          <h3 className='mt-[20px] ml-[40px] text-bold'>
            <b>
            +1 (416) 523-0392
          </b>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default OurContact
