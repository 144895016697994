import React from "react";
import hardware from "../assets/images/ConnectionHardware.svg";
import design from "../assets/images/graphic design.svg";
import code from "../assets/images/Custom Coding.svg";



function ClassRoom() {
  return (
    <div
      style={{
        fontFamily: "'Clash Display', sans-serif",
      }}
      className="mt-[80px]"
    >
      <h1
        style={{
          fontWeight: 900,
        }}
        className="text-center m-5 text-[2rem] md:text-[2rem] text-dark"
      >
        Bring Classroom experience to life
      </h1>
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="w-[80%] md:w-[30%] m-auto bg-white shadow-xl rounded-md mt-[50px] mb-[50px] p-7">
          <img src={hardware} alt="hardware" className="w-16 h-16 m-auto" />
          <h1
            style={{
              fontWeight: 600,
            }}
            className="text-center m-[10px]  text-[1.5rem] md:text-[1.8rem] break-words text-dark"
          >
            Hardware
          </h1>
          <div className="w-full md:w-[80%] m-auto">
            <p className="text-center break-words">
              We offer trainings in hardware programming and embedded systems.
              We seek to bring kids to innovate with their free minds. There's
              great power in the mind.
            </p>
          </div>
        </div>
        <div className="w-[80%] md:w-[30%] m-auto bg-white shadow-xl rounded-md mt-[50px] mb-[50px] p-7">
          <img src={design} alt="Graphic design" className="w-16 h-16 m-auto" />
          <h1
            style={{
              fontWeight: 600,
            }}
            className="text-center m-[10px]  text-[1.5rem] md:text-[1.8rem] break-words text-dark"
          >
            UI/UX
          </h1>
          <div className="w-full md:w-[80%] m-auto">
            <p className="text-center break-words">
              User interfacec and User expereience are two indomiable aspects of
              designing a system be it hardware or software. We would dbe
              training kids on how to bear this skill to their full potential.
            </p>
          </div>
        </div>
        <div className="w-[80%] md:w-[30%] m-auto bg-white shadow-xl rounded-md mt-[50px] mb-[50px] p-7">
          <img src={code} alt="WhatsApp Icon" className="w-16 h-16 m-auto" />
          <h1
            style={{
              fontWeight: 600,
            }}
            className="text-center m-[10px]  text-[1.5rem] md:text-[1.8rem] break-words text-dark "
          >
            Software development
          </h1>
          <div className="w-full md:w-[80%] m-auto">
            <p className="text-center break-words">
              We offer training in front-end and back-end software development.
              Our goal is to inspire kids to innovate with their creativity and
              imagination. There is immense power in the mind, and we seek to
              harness it to foster the next generation of tech innovators.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassRoom;
