import Navbar from "../components/navbar";
import React from "react";
import teamMembers from "../assets/images/team/team2.png";
import gladys from "../assets/images/team/gladys.svg";
import josh from "../assets/images/team/josh.svg";
import deji from "../assets/images/team/deji_steven.svg";
import jeddy from "../assets/images/team/jeddy.svg";
import nehhy from "../assets/images/team/nehhy.svg";
import lesh from "../assets/images/team/lesh.svg";
import issues from "../assets/images/team/issues.svg";
import debby from "../assets/images/team/debby.svg";
import shally from "../assets/images/team/shally.svg";
import ariel from "../assets/images/team/ariel.svg";
import Announcement from "../components/announcement";
import MissionHeader from "../components/missionHead";
import aboutUsImg from "../assets/images/aboutUsCac.svg";
import OurContact from "../components/ourContact";
import Wrapper from "./wrapper";

const About = () => {
  return (
    <>
      <Wrapper>
        <MissionHeader />
        <div className="mt-20"></div>
      </Wrapper>
      {/* <Announcement /> */}
      <div className="px-[30px] md:px-[139px]">
        <div className="grid grid-cols-12 gap-5 md:gap-2">
          <div className="col-span-12 md:col-span-6">
            <img className="m-auto w-[400px]" src={aboutUsImg} />
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="m-auto mt-10 w-full">
              <h1
                style={{
                  fontWeight: 550,
                  fontFamily: "'Clash Display', sans-serif",
                }}
                className="text-[2rem] text-dark"
              >
                Vision
              </h1>
              <p
                style={{
                  fontWeight: 200,
                  fontFamily: "'Clash Display', sans-serif",
                }}
                className="text-left text-dark m-auto text-[16px] md:text-[20px]"
              >
                To create a future where students from diverse and
                underrepresented backgronds in developing nations are equipped
                with STEM skills, driving innovation for technological
                advancement and sustainable developement as leaders and
                problem-solvers in a thriving global community.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Wrapper>
        <div
          style={{
            fontFamily: "'Montserrat', sans-serif",
          }}
          className="px-[30px] md:px-[139px] py-[20px] mt-20"
        >
          <div
            style={{
              fontFamily: "'Clash Display', sans-serif",
            }}
            className="m-auto"
          >
            <h1
              style={{
                fontWeight: 600,
                fontFamily: "'Clash Display', sans-serif",
              }}
              className="text-center m-[10px] mb-10  text-[2rem] md:text-[1.8rem] break-words text-dark"
            >
              Meet The Team
            </h1>
          </div>
          <div className="grid grid-cols-12 md:gap-5">
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={gladys} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Olubowale Gladys
                  </p>
                  <span className="text-[15px]">Founder</span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={josh} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Joshua Wisdom Momo
                  </p>
                  <span className="text-[15px]">
                    Operations Director/Program Director
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={debby} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Adesanya Deborah
                  </p>
                  <span className="text-[15px]">
                    Community manager/Content writer
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={deji} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Stephen Adeniyi Deji
                  </p>
                  <span className="text-[15px] break-words">
                    Assistant Operations <br /> Director/Program Director
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={nehhy} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Nehemiah Amedu Odagboyi
                  </p>
                  <span className="text-[15px]">Human resource director</span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={jeddy} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Jedidiah Oladepo Ifeoluwa
                  </p>
                  <span className="text-[15px]">Graphic Designer</span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={shally} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Ekundayo Shallom
                  </p>
                  <span className="text-[15px]">Developer</span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={lesh} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Lesh Tayo
                  </p>
                  <span className="text-[15px]">Ui/UX Designer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 md:gap-5">
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={issues} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Iseoluwa Gbotemi
                  </p>
                  <span className="text-[15px]">Developer</span>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 mb-5">
              <div className="m-auto w-fit">
                <img src={ariel} className="" />
                <div className="mt-2">
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                    className="text-start text-[16] text-black"
                  >
                    Ariel Hne
                  </p>
                  <span className="text-[15px]">
                    Community manager/Content writer
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <OurContact />
    </>
  );
};

export default About;
