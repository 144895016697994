import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Loader from "./loader";
import Wrapper from "../wrapper";
import { IoLogoWhatsapp } from "react-icons/io";

function Submit() {
  const [message, setMessage] = useState(" ");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // Initially set to false

  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 300000); // 120000 ms = 2 minutes
    }
    return () => clearTimeout(timer); // Cleanup timer if component unmounts or success changes
  }, [success]);

  const handleSubmit = async () => {
    setLoading(true);
    const generalFormData = JSON.parse(localStorage.getItem("formData")) || {};
    const courseFormData =
      JSON.parse(localStorage.getItem("courseFormData")) || {};

    const mergedFormData = { ...generalFormData, ...courseFormData };
    console.log("Course Form Data:", mergedFormData);

    try {
      const response = await fetch(
        "https://xplore-backend-o4me.onrender.com/api/registration/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mergedFormData),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        setMessage(errorResponse.error || "Network response was not ok");
        console.log(errorResponse.error);
        setLoading(false);
        return;
      }

      const result = await response.json();
      setMessage(result.message);
      localStorage.removeItem("formData");
      localStorage.removeItem("courseFormData");
      setSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        fontFamily: "'Clash Display', sans-serif",
      }}
    >
      <Wrapper>
        {loading && <Loader />}
        <nav>
          <ul
            style={{
              fontFamily: "'Clash Display', sans-serif",
              fontWeight: 500,
            }}
            className="flex w-[90%] m-auto justify-between text-[14px] mt-[250px]"
          >
            <li>
              <NavLink
                to="/register"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                General Details
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/course-selection"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                Course Selection
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/submit"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                Submit
              </NavLink>
            </li>
          </ul>
        </nav>
        <article className="w-[80%] shadow-2xl rounded-md p-[20px] m-auto mb-[50px] mt-[50px] h-[300px]">
          <p
            style={{
              fontFamily: "'Clash Display', sans-serif",
              fontWeight: 900,
            }}
            className="text-center m-5 text-[22px] md:text-[30px] text-primary font-bold mt-[100px] md:w-[50%] md:m-auto md:mt-[80px]"
          >
            Ensure you go over all the information you have provided before
            submitting.
          </p>
          {success && (
            <div className="fixed top-20 left-1/2 transform z-2000  -translate-x-1/2 bg-white p-4 shadow-lg flex items-center border rounded-md ">
              <IoLogoWhatsapp size={50} color="#231651" />
              <p className="ml-2">
                Use this link to join our WhatsApp group:{" "}
                <a
                  href="https://chat.whatsapp.com/GbToN7d2xVN7RpDIXMrOLC"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary underline"
                >
                  Join Now
                </a>
              </p>
            </div>
          )}
        </article>
        <p className="text-center text-[#c32929]">{message}</p>
        <div className="flex justify-between w-[90%] m-auto">
          <NavLink
            to="/course-selection"
            className="p-[10px] rounded-md m-[20px] bg-primary text-white hover:cursor-pointer"
          >
            Previous
          </NavLink>
          <button
            onClick={handleSubmit}
            className="p-[10px] rounded-md m-[20px] bg-primary text-white hover:cursor-pointer"
          >
            Submit
          </button>
        </div>
      </Wrapper>
    </div>
  );
}

export default Submit;
