import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from "./components/navbar.js";
import Home from "./pages/home.js";
import News from "./pages/news.js";
import "./index.css";
import About from './pages/about.js';
import Footer from './components/footer.js';
import Trainings from "./pages/training.js";
import Instructors from "./pages/instructors.js";
import GeneralDetails from './pages/registeration/generalDetails.js';
import CourseSelection from './pages/registeration/courseSelection.js';
import Submit from './pages/registeration/submit.js';
import RegistrationList from './pages/xplore-form.js';

// import Announcement from './components/announcement';



function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/training" element={<Trainings />} />
          <Route path="/instructors" element={<Instructors />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/register" element={<GeneralDetails />} />
          <Route path="/course-selection" element={<CourseSelection />} />
          <Route path="/submit" element={<Submit />} />
          <Route path="/form" element={<RegistrationList />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
