import React from "react";
import bgImage from "../assets/images/real_background_1 16.png";
import "../css/wrapper.css";

const Wrapper = ({ children }) => {
  return (
    <div className="wrapper-background">
      <div className="background-image"></div>
      <div className="content">{children}</div>
    </div>
  );
};

export default Wrapper;
