import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Wrapper from "../wrapper";
import Line from '../../assets/images/Line.svg'
import RegistrationClosed from "./registrationClosed";

function GeneralDetails() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dateOfBirth: "",
    age: "",
    whatsappNumber: "",
  });

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("formData"));
    if (savedFormData) {
      setFormData(savedFormData);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevFormData) => {
      const newValue = type === "number" ? Number(value) : value;
      const newFormData = { ...prevFormData, [name]: newValue };
      localStorage.setItem("formData", JSON.stringify(newFormData));
      return newFormData;
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    // Optionally, validate form data here before navigating
  };

  return (
    <div
      style={{
        zIndex: 99,
        fontFamily: "'Clash Display', sans-serif",
      }}
    >
      <RegistrationClosed/>
      {/* <Wrapper>
        <h1
          style={{ fontFamily: "'Clash Display', sans-serif", fontWeight: 900 }}
          className="text-center mt-[150px] m-5 text-[30px] md:text-[35px] text-primary font-bolder"
        >
          Let’s get you registered
        </h1>
        <p
          style={{ fontFamily: "'Clash Display', sans-serif", fontWeight: 900 }}
          className="text-center m-5 text-[22px] md:text-[25px] text-[#4B4B4B] font-bolder"
        >
          Enter your details to get going
        </p>
        <nav>
          <ul
            style={{
              fontFamily: "'Clash Display', sans-serif",
              fontWeight: 500,
            }}
            className="flex w-[90%] m-auto justify-between text-[14px]"
          >
            <li>
              <NavLink
                to="/register"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                General Details
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/course-selection"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                Course Selection
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/submit"
                className={({ isActive }) =>
                  `text-lg ${
                    isActive ? "font-bold text-primary underline" : ""
                  }`
                }
              >
                Submit
              </NavLink>
            </li>
          </ul>
        </nav>
        <img src={Line} className="w-[90%] m-auto h-[20px] " />
        <form
          className="bg-white w-[90%] md:w-[70%]  rounded-md shadow-2xl  m-auto p-[30px] mt-[50px] mb-[30px]"
          onSubmit={handleNext}
        >
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                First Name<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Write your firstname"
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              />
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Last Name<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Write your lastname"
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Email Address<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Write your email address"
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              />
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Phone Number<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="start with a country code"
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Age<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                placeholder="Enter your age"
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
                min="0"
              />
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                WhatsApp Number<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="text"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                onChange={handleChange}
                placeholder="start with a country code"
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Gender<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              >
                <option value="">Select your gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="w-[90%] m-auto mt-[10px]">
              <label className="font-medium text-[17px]">
                Date of Birth<span className="text-[red] ml-1">*</span>
              </label>
              <br />
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                className="border border-black rounded-md mt-[5px] p-[4px] w-[90%]"
              />
            </div>
          </div>
        </form>
        <div className="flex justify-between w-[90%] m-auto">
          <NavLink
            to={{
              pathname: "/course-selection",
              state: { formData },
            }}
            className="p-[10px] ml-auto rounded-md m-[20px] bg-primary text-white hover:cursor-pointer"
          >
            Save & Continue
          </NavLink>
        </div>
      </Wrapper> */}
    </div>
  );
}

export default GeneralDetails;
