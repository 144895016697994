import InstructorHeader from "../components/instructorHeader";
import josh from "../assets/images/team/josh.svg";
import topTea from "../assets/images/team/shally.svg";
import gladys from "../assets/images/team/gladys.svg";
import lesh from "../assets/images/team/lesh.svg";
import issues from "../assets/images/team/issues.svg";
import Announcement from "../components/announcement";
import Wrapper from "./wrapper";

const Instructors = () => {
  return (
    <>
      <div className="px-[30px] md:px-[139px]">
        <div>
          <Wrapper>
            <InstructorHeader />
          </Wrapper>
          <Announcement />
        </div>
        <h1
          style={{
            fontFamily: "'Clash Display', sans-serif",
          }}
          className="text-center text-[2.3rem] m-[auto]   font-bold text-dark"
        >
          Meet the Instructors
        </h1>
        <div className=" grid grid-cols-12 gap-2 md:gap-15">
          <div
            style={{
              fontFamily: "'Montserrat', sans-serif",
            }}
            className="col-span-12 md:col-span-4 m-auto shadow-xl mt-[30px] mb-[30px] text-center rounded-lg p-[30px] "
          >
            <img className=" m-auto rounded-full" src={gladys} />
            <p className=" text-center text-[black] font-bold mt-3">
              Olubowale Gladys Opeoluwa
            </p>
            <p className=" text-center text-[#9D9898] ">Founder and CEO</p>
            <p className=" text-center text-[#9D9898] text-[0.9rem] mt-[10px] ">
              Chemical Engineering and Mastercard Foundation scholar, University
              of Toronto, Canada Research Project Management Process Engineering
              Intern, Vale Ltd Canada
            </p>
          </div>
          <div
            style={{
              fontFamily: "'Montserrat', sans-serif",
            }}
            className="col-span-12 md:col-span-4 m-auto shadow-xl mt-[30px] mb-[30px] text-center rounded-lg p-[30px] "
          >
            <img className=" m-auto rounded-full" src={josh} />
            <p className=" text-center text-[black] font-bold mt-3">
              {" "}
              Joshua Momo
            </p>
            <p className=" text-center text-[#9D9898] ">
              Operations Director/Program Director{" "}
            </p>
            <p className=" text-center text-[#9D9898] text-[0.9rem] mt-[10px] ">
              Graduate Student in the MS Engineering AI program at CMU-Africa
            </p>
          </div>

          <div
            style={{
              fontFamily: "'Montserrat', sans-serif",
            }}
            className="col-span-12 md:col-span-4 m-auto shadow-xl mt-[30px] mb-[30px] text-center rounded-lg p-[30px] "
          >
            <img className=" m-auto rounded-full" src={lesh} />
            <p className=" text-center text-[black] font-bold mt-3">
              Lesh Tayo
            </p>
            <p className=" text-center text-[#9D9898] ">UI/UX designer</p>
            <p className=" text-center text-[#9D9898] text-[0.9rem] mt-[10px] ">
              Computer science student at Ekiti State University, Nigeria
            </p>
          </div>
          <div
            style={{
              fontFamily: "'Montserrat', sans-serif",
            }}
            className="col-span-12 md:col-span-4 m-auto shadow-xl mt-[30px] mb-[30px] text-center rounded-lg p-[30px] "
          >
            <img className=" m-auto rounded-full" src={topTea} />
            <p className=" text-center text-[black] font-bold mt-3">
              Ekundayo Shallom
            </p>
            <p className=" text-center text-[#9D9898] ">Developer</p>
            <p className=" text-center text-[#9D9898] text-[0.9rem] mt-[10px] ">
              Computer science student at Univeristy of Jos, Nigeria
            </p>
          </div>
          <div
            style={{
              fontFamily: "'Montserrat', sans-serif",
            }}
            className="col-span-12 md:col-span-4 m-auto shadow-xl mt-[30px] mb-[30px] text-center rounded-lg p-[30px] "
          >
            <img className=" m-auto rounded-full" src={issues} />
            <p className=" text-center text-[black] font-bold mt-3">
              Iseoluwa Gbotemi
            </p>
            <p className=" text-center text-[#9D9898] ">Developer</p>
            <p className=" text-center text-[#9D9898] text-[0.9rem] mt-[10px] ">
              Computer science student at Univeristy of Toronto
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructors;
